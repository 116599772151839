<template>
  <div class="content">
    <!-- 内容 -->
    <template v-for="(item, i) in data">
      <div :key="i" class="contentArr">
        <div class="contentText">
          <div class="gang"></div>
          <h3>{{ item.title }}</h3>
          <p v-html="item.text"></p>
          <img :src="item.imgSrc" alt="" class="img1" v-if="item.state" />
          <video :src="item.imgSrc" alt="" class="img1" controls v-else />
        </div>
      </div>
    </template>
    <!-- 内容 -->
  </div>
</template>
<script>
export default {
  data() {
    return {
      data: [
        {
          state: true,
          imgSrc: require("@/assets/img/factoryCase1.jpg"),
          title: "康佳电子",
          text: "2017年，秀圆果原班团队承担建设安徽康佳电子RFID系统项目，应用于电视机产品的生产、仓储、营销智能化管理。安徽康佳公司目前是康佳集团规模最大的彩电制造基地，也是国内单体最具规模的彩电制造企业之一，拥有12条现代化平板电视生产线，年彩电生产能力600万台。该项目基于RFID与微信二维码相结合的方式，为康佳电视提供了全生产和销售流程的应用解决方案，结合其生产环境研发智能识别设备套件，并与现有生产和储运系统对接，实现生产环节生产过程全自动监测、快速出入库识别、智能盘点等应用，与下游销售分公司和第三方经销商系统快速入库对接。该项目是生产制造企业智能化应用的典型之作，涵盖了生产、仓储、物流等环节，具备完善的供应链管理体系和质量追溯体系。项目的应用大大降低了人工成本，节约了作业节操作时间，创建了防窜货管理新方法，同时也提升了与消费者的互动新模式，促进市场数据分析和精准营销。",
        },
        {
          state: false,
          imgSrc: "https://jsxygkj.oss-cn-hangzhou.aliyuncs.com/official_site/秀圆果防伪溯源系统（logo）.mp4",
          title: "洋河股份RFID项目建设",
          text: "洋河股份RFID防伪溯源系统由秀圆果原班团队建设。为升级RFID应用，在现场对三条生产线共30个工位点进行了RFID应用改造，应用到洋河梦之蓝所有产品。<br>在建设全系列产品追溯体系时，对洋河基地、泗阳基地所有包装生产线上增加了物流箱码自动采集装置，实现了单瓶的RFID和追溯码与物流箱码自动关联。同时RFID系统连通洋河SAP，实现了原料、生产、仓储、物流、销售全过程追溯信息链条打通。",
        },
        {
          state: true,
          imgSrc: require("@/assets/img/rectangle4.png"),
          title: "大全集团电子签章",
          text: "大全集团是电气、新能源、轨道交通领域的领先制造商，主要研发生产中低压成套电器设备、智能元器件、轨道交通设备、新能源硅材料等。是中国机械工业100强企业。<br>秀圆果于2022年9月与大全集团建立合作，秀圆果公司为大全集团建设电子签章和电子合同管理系统。电子签章系统为大全集团内部、以及供应链体系中的用户对象提供多方实时同步在线签章、安全电子印章数字证书查询、日志档案查询、签章文件真伪验证、发文和收文时间认证等功能。",
        },
      ],
    };
  },
  methods: {},
};
</script>
<style lang="scss" scoped>
.content {
  // 偶数列背景颜色
  .contentArr:nth-child(even) {
    background-color: #f4f4f4;
  }
}
@media screen and (max-width: 750px) {
  .content {
    // 内容样式
    .contentArr {
      background: #fff;
      width: 100%;
      overflow: hidden;
      .contentText {
        padding-bottom: 2.8rem;
        width: 15.87rem;
        margin: auto;
        margin-top: 1.6rem;
        .gang {
          width: 0.8rem;
          height: 0.1rem;
          background: #093c7f;
        }
        h3 {
          font-size: 0.92rem;
          font-family: HarmonyOS Sans-Bold, HarmonyOS Sans;
          font-weight: bold;
          color: #093c7f;
          margin-top: 0.1rem;
        }
        p {
          // text-indent: 0.48rem;
          font-size: 0.72rem;
          font-family: HarmonyOS Sans-Regular, HarmonyOS Sans;
          font-weight: 400;
          color: #505050;
          line-height: 1.02rem;
        }
        .img1 {
          width: 15.87rem;
          height: 10.09rem;
          margin-top: 0.46rem;
        }
      }
    }
  }
}
@media only screen and (max-width: 19200px) and (min-width: 751px) {
  // 内容样式
  .contentArr {
    background: #fff;
    width: 19.2rem;
    overflow: hidden;
    .contentText {
      width: 100%;
      float: left;
      position: relative;
      padding-bottom: 2.8rem;
      margin: 1.6rem 0 0 1.69rem;
      .gang {
        width: 0.8rem;
        height: 0.1rem;
        background: #093c7f;
      }
      h3 {
        height: 0.84rem;
        font-size: 0.5rem;
        font-family: PingFang SC-Heavy, PingFang SC;
        font-weight: 800;
        color: #093c7f;
        line-height: 0.7rem;
        margin-top: 0.05rem;
      }
      p {
        // text-indent: 0.48rem;
        margin-top: 0.2rem;
        width: 8.71rem;
        font-size: 0.2rem;
        font-family: HarmonyOS Sans-Regular, HarmonyOS Sans;
        font-weight: 400;
        color: #000000;
        line-height: 0.36rem;
      }
      .img1 {
        width: 6.78rem;
        height: 4.32rem;
        position: absolute;
        top: 0.47rem;
        left: 9rem;
      }
      .care {
        height: 0.25rem;
        font-size: 0.18rem;
        font-family: PingFang SC-Medium, PingFang SC;
        font-weight: 500;
        color: #093c7f;
        line-height: 0.21rem;
        margin-top: 0.2rem;
        .careContent {
          margin-bottom: 0.2rem;
        }
      }
    }
  }
}
</style>
